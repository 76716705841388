
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { PieChart } from '@mui/x-charts/PieChart';
import React, { useState, useEffect, useCallback } from 'react';
import Filters from '../../componentes/Filters/index.js';
import { useNavigate } from "react-router-dom";
import VesselsChart from './VesselsChart';
import { BarChart } from '@mui/x-charts/BarChart';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import * as XLSX from 'xlsx';
import Button from '@mui/material/Button';
import { Download as DownloadIcon } from '@mui/icons-material';


function useWindowDimensions() {
    const hasWindow = typeof window !== 'undefined';

    const getWindowDimensions = useCallback(() => {
        const width = hasWindow ? window.innerWidth : null;
        const height = hasWindow ? window.innerHeight : null;
        return { width, height };
    }, [hasWindow]);

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        if (hasWindow) {
            const handleResize = () => {
                setWindowDimensions(getWindowDimensions());
            };

            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    }, [hasWindow, getWindowDimensions]);

    return windowDimensions;
}
const HomePage = () => {
    let { height } = useWindowDimensions();
    height = height * 0.65;

    const navigate = useNavigate();
    const [selectedFilters, setSelectedFilters] = useState({
        equipment_identifiers: [],
        ship_owners: [],
        describe_info: [],
        severities: [],
        start_date: dayjs().subtract(7, 'day'),
        end_date: dayjs(),
    });

    const baseUrl = process.env.REACT_APP_LISTEN_ADDRESS;
    const user = JSON.parse(localStorage.getItem("user"));
    const [eventQuantity, setEventQuantity] = useState(0);
    const [eventsBySeverity, setEventsBySeverity] = useState([]);
    const [eventsByAlert, setEventsByAlert] = useState([]);
    const [eventsByVessel, setEventsByVessel] = useState([]);
    const [alertsBarlabels, setAlertsBarlabels] = useState([]);
    const [vesselsBarlabels, setVesselsBarlabels] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dashboardData, setDashboardData] = useState(null);
    const [equipments, setEquipments] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); // Start loading
            try {
                const filterParams = new URLSearchParams();
                Object.entries(selectedFilters).forEach(([key, value]) => {
                    if (Array.isArray(value)) {
                        value.forEach(val => filterParams.append(key, val));
                    } else if (value) {
                        filterParams.append(key, key.includes('date') ? dayjs(value).format() : value);
                    }
                });

                const response = await fetch(`${baseUrl}/sync-event/dashboard?${filterParams}`, {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                });

                if (!response.ok) {
                    if (response.status === 401) {
                        localStorage.removeItem("user");
                        navigate("/login", { replace: true });
                    } else {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                }

                const responseEquipment = await fetch(`${baseUrl}/equipment`, {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                });


                if (!responseEquipment.ok) {
                    if (response.status === 401) {
                        localStorage.removeItem("user");
                        navigate("/login", { replace: true });
                        return;
                    } else {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                }

                const dataEquipment = await responseEquipment.json();
                setEquipments(dataEquipment);

                let data = await response.json();
                setDashboardData(data);
                setEventQuantity(data.countTotalEvents);

                const uniqueDescribeInfo = Object.keys(data.countEventsWithDescribeInfo);
                setEventsByAlert(uniqueDescribeInfo.map(info => data.countEventsWithDescribeInfo[info]));
                setAlertsBarlabels(uniqueDescribeInfo);

                const uniqueEquipment = Object.keys(data.countEventsPerEquipment);
                setEventsByVessel(uniqueEquipment.map(info => data.countEventsPerEquipment[info]));
                setVesselsBarlabels(uniqueEquipment.map(String));

                setEventsBySeverity([
                    { label: 'Normal', value: data.countSeverity['Normal'], color: 'green' },
                    { label: 'Crítico', value: data.countSeverity['Crítico'], color: 'darkred' },
                ]);

            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false); // Start loading
            }
        };

        if (user.token) {
            fetchData();
        }
    }, [baseUrl, user.token, navigate, selectedFilters]);


    const getDifferenceInDays = (lastKeepalive) => {
        const currentDate = new Date();
        const gmtOffset = -3;
        const lastKeepaliveDate = lastKeepalive ? new Date(new Date(lastKeepalive).getTime() + (gmtOffset) * 60 * 60 * 1000) : null;
        const differenceInDays = lastKeepaliveDate ? Math.floor((currentDate - lastKeepaliveDate) / (24 * 60 * 60 * 1000)) : null;
        return differenceInDays;
    };


    const getEquipmentStatus = (equipment) => {
        const differenceInDays = getDifferenceInDays(equipment.last_keepalive_ts_received);
        if (differenceInDays > 7) return 'offline';
        if (equipment.last_keepalive_ts_received) return 'online';
        return 'unavailable';
    };

    const calculateEquipmentCounts = () => {
        const counts = { online: 0, offline: 0, unavailable: 0 };

        equipments.forEach((equipment) => {
            const status = getEquipmentStatus(equipment);
            counts[status]++;
        });
        return counts;
    };

    const formatDate = (date) => {
        const options = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        };

        return new Intl.DateTimeFormat('pt-BR', options).format(new Date(date));
    };


    const { online, offline, unavailable } = calculateEquipmentCounts();

    const exportToXls = () => {
        if (!dashboardData) return;

        const eventRows = Object.entries(dashboardData.countEventsWithDescribeInfo).map(([eventType, count]) => ({
            "Desvio": eventType,
            "Quantidade": count
        }));

        const equipmentRows = Object.entries(dashboardData.countEventsPerEquipment).map(([equipment, count]) => ({
            "Embarcação": equipment,
            "Eventos": count
        }));

        const severityRows = [
            { "Severidade": 'Normal', "Quantidade": dashboardData.countSeverity.Normal },
            { "Severidade": 'Crítico', "Quantidade": dashboardData.countSeverity.Crítico }
        ];

        const filterRows = [
            { "Parâmetro": 'Navios', "Valores": selectedFilters.equipment_identifiers.join(", ") },
            { "Parâmetro": 'Armadores', "Valores": selectedFilters.ship_owners.join(", ") },
            { "Parâmetro": 'Desvios', "Valores": selectedFilters.describe_info.join(", ") },
            { "Parâmetro": 'Severidades', "Valores": selectedFilters.severities.join(", ") },
            { "Parâmetro": 'Data início', "Valores": dayjs(selectedFilters.start_date).format('DD-MM-YYYY') },
            { "Parâmetro": 'Data fim', "Valores": dayjs(selectedFilters.end_date).format('DD-MM-YYYY') }
        ];

        const wb = XLSX.utils.book_new();

        const eventSheet = XLSX.utils.json_to_sheet(eventRows);
        XLSX.utils.book_append_sheet(wb, eventSheet, 'Eventos por desvio');

        const equipmentSheet = XLSX.utils.json_to_sheet(equipmentRows);
        XLSX.utils.book_append_sheet(wb, equipmentSheet, 'Eventos por embarcação');

        const severitySheet = XLSX.utils.json_to_sheet(severityRows);
        XLSX.utils.book_append_sheet(wb, severitySheet, 'Eventos por severidade');

        const filterSheet = XLSX.utils.json_to_sheet(filterRows);
        XLSX.utils.book_append_sheet(wb, filterSheet, 'Parâmetros de filtragem');

        const dateTime = dayjs().format('DD-MM-YYYY_HH-mm');

        XLSX.writeFile(wb, `dashboard_report_${dateTime}.xls`, { bookType: 'xls', bookSST: false });

    };
    const handleFilterChange = (filterType, selectedOptions) => {
        setSelectedFilters(prevFilters => ({ ...prevFilters, [filterType]: selectedOptions }));
    };

    const alertsData = eventsByAlert;
    const vesselsData = eventsByVessel;
    const alertsXLabels = alertsBarlabels;
    const vesselsXLabels = vesselsBarlabels;
    const piedata = eventsBySeverity;

    return (
        <>
            <Paper
                variant="outlined"
                sx={{
                    m: 2,
                    p: 1.5,
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'wrap',
                    boxSizing: 'border-box',
                }}
            >
                <Filters onFilterChange={handleFilterChange} sx={{ width: '100%', m: 2 }} />

                <Button
                    onClick={exportToXls}
                    sx={{
                        display: 'flex',
                        m: 1,
                        borderRadius: 2,
                        alignSelf: 'flex-end',
                    }}
                    variant="contained"
                    color="primary"
                    startIcon={<DownloadIcon />}
                >
                    <Typography variant="button" sx={{ textTransform: 'none' }}>
                        Exportar (.XLS)
                    </Typography>
                </Button>

                <Box
                    sx={{
                        display: 'flex',
                        gap: 1,
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                    }}
                >
                    <Box
                        sx={{
                            minWidth: 275,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                            flex: 1,
                            boxSizing: 'border-box',
                        }}
                    >

                        <Card
                            sx={{
                                flex: '1',
                                p: 2,
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Typography gutterBottom fontWeight="bold" fontSize="1.5rem" component="div">
                                Alertas
                            </Typography>

                            <Typography
                                sx={{
                                    color: 'text.secondary',
                                    textAlign: 'center',
                                    maxWidth: '100%',

                                }}
                            >
                                {selectedFilters.start_date && selectedFilters.end_date
                                    ? (formatDate(selectedFilters.start_date) === formatDate(selectedFilters.end_date)
                                        ? `${formatDate(selectedFilters.start_date)}`
                                        : `${formatDate(selectedFilters.start_date)} até ${formatDate(selectedFilters.end_date)}`)
                                    : selectedFilters.start_date
                                        ? `Alertas a partir de ${formatDate(selectedFilters.start_date)}`
                                        : selectedFilters.end_date
                                            ? `Alertas até ${formatDate(selectedFilters.end_date)}`
                                            : 'Todo o período'}

                            </Typography>

                            <Typography gutterBottom variant="h2" component="div">
                                {eventQuantity}
                            </Typography>
                        </Card>

                        <Card
                            sx={{
                                flex: '1',
                                p: 2,
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Typography align="center" gutterBottom fontWeight="bold" fontSize="1.5rem" component="div">
                                Embarcações
                            </Typography>

                            <Typography gutterBottom variant="h2" component="div" fontWeight="bold">
                                {online + offline + unavailable}
                            </Typography>

                            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                <Typography variant="body" fontWeight="bold" sx={{ color: 'green' }}>
                                    Online: {online}
                                </Typography>
                                <Typography variant="body" fontWeight="bold" sx={{ color: 'red' }}>
                                    Offline: {offline}
                                </Typography>
                                <Typography variant="body" fontWeight="bold" sx={{ color: 'gray' }}>
                                    Indisponível: {unavailable}
                                </Typography>
                            </Box>
                        </Card>

                        <Card sx={{ p: 2, position: 'relative', flex: '2', minHeight: 250 }}>
                            <Typography align="center" gutterBottom fontWeight="bold" fontSize="1.5rem" component="div">
                                Relação de Alertas Críticos
                            </Typography>
                            {piedata.length > 0 ? (
                                <PieChart
                                    slotProps={{
                                        legend: {
                                            direction: 'row',
                                            position: { vertical: 'bottom', horizontal: 'middle' },
                                            padding: 0,
                                        },
                                    }}
                                    series={[{
                                        arcLabel: (item) => `${item.value}`,
                                        arcLabelMinAngle: 25,
                                        arcLabelRadius: '60%',
                                        startAngle: -90,
                                        endAngle: 90,
                                        data: piedata,
                                    }]}
                                    height={height * 0.4}
                                    margin={{ top: 10, bottom: 0, left: 0, right: 0 }}
                                />
                            ) : (
                                <Typography variant="body2" color="textSecondary" align="center" sx={{ flex: 1 }}>
                                    Sem dados para exibir.
                                </Typography>
                            )}
                        </Card>
                    </Box>

                    <Card
                        sx={{
                            p: 1,
                            flex: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            minWidth: 300,
                            minHeight: 300,
                            boxSizing: 'border-box',
                        }}
                    >
                        <Typography align="center" gutterBottom fontWeight="bold" fontSize="1.8rem" component="div">
                            Desvios por alerta
                        </Typography>
                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                            {alertsData.length > 0 ? (
                                <BarChart
                                    height={height}
                                    series={[{ data: alertsData }]}
                                    barLabel={(item, context) => {
                                        return context.bar.height < 30 ? null : item.value?.toString();
                                    }}
                                    margin={{ bottom: 100 }}
                                    sx={{ p: 3, m: 0, '& .MuiBarLabel-root': { fontWeight: 'bold' } }}
                                    xAxis={[{
                                        data: alertsXLabels,
                                        scaleType: 'band',
                                        tickLabelStyle: { angle: 25, textAnchor: 'start', fontSize: 12 },
                                    }]}
                                />
                            ) : (
                                <Typography variant="body2" color="textSecondary" align="center" sx={{ flex: 1 }}>
                                    Sem dados para exibir.
                                </Typography>
                            )}
                        </div>
                    </Card>

                    <VesselsChart
                        vesselsData={vesselsData}
                        vesselsXLabels={vesselsXLabels}
                        height={height}
                    />
                </Box>

                <Backdrop
                    sx={(theme) => ({
                        color: '#fff',
                        zIndex: theme.zIndex.drawer + 2,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    })}
                    open={loading}
                    onClick={(e) => e.stopPropagation()}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

            </Paper>
        </>
    );
}

export default HomePage;
