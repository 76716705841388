export const userRoles = {
    admin: "Admin",
    petrobras: "Petrobras",
    bravante: "BRAVANTE",
    oceanpact: "OCEANPACT",
    wsut: "WSUT",
    camorim: "CAMORIM",
    starnav: "STARNAV",
    cbo: "CBO",
    ambipar: "AMBIPAR",
    asso: "ASSO",
    lotus: "Lotus",
    privado: "Privado",
    aceleracao: "ACELERACAO"
}
